import React from 'react';
import {Badge} from "react-bootstrap";

function ProjectStatus(props) {
    if (props.ongoing) {
        return (
            <div>
                <h4>
                    <Badge bg="success">Ongoing</Badge>
                </h4>
            </div>
        );
    }
    return (
        <div>
            <h4>
                <Badge bg="primary">Past Project</Badge>
            </h4>
        </div>
    );
}

export default ProjectStatus;