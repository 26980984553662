import React from 'react';
import './custom-button.css'

function CustomButtonDown(props) {
    return (
        <button href="#aboutBreakSec" className={`custom-btn btn-15 ${props.color}`}>
            <a href={props.target_link}>{props.text}</a></button>
    );
}

export default CustomButtonDown;