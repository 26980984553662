import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


function ProjectDetailsFarming() {
    return (
        <>
            <style type="text/css">
                {`
    .nav-pills .nav-link.active {
      background-color: #bb3b3b;
      color: white;
    }
    
    .nav-link {
        color: black;
    }
     .nav-link:hover {
        color: #bb3b3b;
    }
    
    `}
            </style>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column projDetails">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Low Pressure Growth Chamber</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Earth Lab Prototype</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Mars Lab</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <p>The first deliverable for this project was to create a growth chamber in which the team could grow plant at reduced pressures. It is theorized that growing at reduced pressures may increase food production, and this chamber was intended to test that theory. Additionally, growing at reduced pressures means the structure containing the farming module would have less structural/mass requirements. Major issues were encountered as the team delved into this first part, and it was decided to put this project on an indefinite hold.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <p>The next step after the completion of the Low-Pressure Growth Chamber would have been the creation of an “Earth lab”, an automated farming proof of concept that would be able to grow and harvest a selection of three plants with minimal human intervention.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <p>The last step of the project would have been the creation of a CAD model of a potential full-size farming module to be installed on the first human settlement on Mars.</p>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        </>
    );
}

export default ProjectDetailsFarming;