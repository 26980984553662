import React from 'react';
import './socials.css';

function Socials(props) {
    return (
        <div className="footer-social-links">
            <a href="https://www.instagram.com/ubcmarscolony/?hl=en" title="Instagram" target="_blank"><i className="fa-brands fa-instagram"></i></a>
            <a href="https://www.linkedin.com/company/ubc-mars-colony/?originalSubdomain=ca" title="Linkedin" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
            <a href="https://www.facebook.com/ubcmarscolony/" title="Facebook" target="_blank"><i className="fa-brands fa-facebook"></i></a>
            <a href="https://twitter.com/ubc_mars_colony?lang=en" title="Twitter" target="_blank"><i className="fa-brands fa-twitter"></i></a>
            <a href="mailto:ubcmarscolony@gmail.com" title="gmail" target="_blank"><i className="fa-regular fa-envelope"></i></a>
        </div>
    );
}

export default Socials;