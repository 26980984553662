import React from 'react';
import './aboutinfo.css';
import AboutUsSlider from "../About Us/about-us-slider";
import CustomButton from "../Extras/CustomButton";

function AboutInfo(props) {
    return (
        <div className="about-informations">
            <div className="about-title">
                <h1 className='big-title'>WHO<br/>ARE<br/>WE?</h1>
                <div className="sliding-img">
                    <AboutUsSlider/>
                </div>
            </div>
            <p className="about-desc">We are a design team focused on the goal of making life on Mars sustainable, while also expanding horizons in our local communities, through the technical innovations while nurturing growth and diversity in the next generation of STEM education.</p>
        </div>
    );
}

export default AboutInfo;