import React from 'react';
import './hero-text-part.css';
import CustomButton from "../Extras/CustomButton";
import Socials from "../Extras/Socials";
import IMAGES from "../../images/images";

function HeroTextPart(props) {
    return (
        <div className='text-part-container'>
            <h3 className='Description'>An <span className='red'>Engineering</span> Student Team</h3>
            <div className="title-with-logo">
                <h1 className='hero-title'>UBC MARS COLONY</h1>
            </div>
            <Socials/>
            
            {/* //uncomment */}
            {/*<div className="button-area">*/}
            {/*    <CustomButton target={"_blank"} target_link={"https://ubc.ca1.qualtrics.com/jfe/form/SV_5zL4WGSMOouKCnI"} text={"Apply Now!"} color={""}/>*/}
            {/*</div>*/}
        </div>
    );
}

export default HeroTextPart;