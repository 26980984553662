const IMAGES = {
    hero_1: require('./hero-carousel/1.png'),
    hero_2: require('./hero-carousel/2.png'),
    hero_3: require('./hero-carousel/3.jpg'),
    hero_4: require('./hero-carousel/4.png'),
    hero_5: require('./hero-carousel/5.jpg'),
    hero_6: require('./hero-carousel/6.png'),
    hero_7: require('./hero-carousel/7.png'),
    hero_8: require('./hero-carousel/8.png'),
    mars_bg: require('./mars-bg.jpg'),
    bg_logo: require('./bg-with-logo.png'),
    logo: require('./round-white-logo.png'),
    team_photo: require('./team-img-2022.png'),
    sponsor_1: require('./Sponsors/Aalborg_logo.png'),
    sponsor_2: require('./Sponsors/CanadianTire_logo.png'),
    sponsor_3: require('./Sponsors/CascadeAerospace_logo.png'),
    sponsor_4: require('./Sponsors/depmech_logo.png'),
    sponsor_5: require('./Sponsors/ece_logo.png'),
    sponsor_6: require('./Sponsors/EGBC_logo.png'),
    sponsor_7: require('./Sponsors/Fluor_logo.png'),
    sponsor_8: require('./Sponsors/GeneralDynamics_logo.png'),
    sponsor_9: require('./Sponsors/Global_logo.png'),
    sponsor_10: require('./Sponsors/Misumi_logo.png'),
    sponsor_11: require('./Sponsors/Protocase_logo.png'),
    sponsor_12: require('./Sponsors/EGBC_logo.png'),
    navLogo: require('./newLogo.png'),
    sabatierImg: require('./sabatier-img.png'),
    farmingImg: require('./plantThingy.jpg'),
    heliosImg: require('./helios.jpg'),
    iac:require('./iac.jpg'),
    team1: require('./team1.jpg'),
    testImg: require('./MicrosoftTeams-image.png'),
    mainTrans: require('./main-bg-0.75.png'),
    sabatierBg: require('./sabatierPage/sabatierbg.jpg'),
    marsChamber: require('./MarsChamber.png'),
    airlock_1: require('./airlockImage/airlock1.png'),
    airlock_2: require('./airlockImage/airlock2.png'),
    airlock_3: require('./airlockImage/airlock3.png'),
    airlock_4: require('./airlockImage/airlock4.png'),
    airlock_5: require('./airlockImage/airlock5.png'),
}

export default IMAGES;