import React from 'react';
import "./airlock-style.css";
import IMAGES from "../../../images/images";
import items from "./airlockTimelineItems";
import {Chrono} from "react-chrono";
import ProjectDetailsAirlock from "./ProjectDetailsAirlock";
import Contacts from "../../Extras/Contacts";
import ImageCard from "../../Extras/imageCard";
import ProjectStatus from "../ProjectStatus";

function AirlockPage(props) {
    return (
        <div>
            <section id="sec-airlock-title">
                <div className="main-title-airlock">
                    <ProjectStatus ongoing={false}/>
                    <h1> AIRLOCK <br/> INAUGURATION </h1>
                    <h3><i>The inaugural project</i></h3>
                </div>
            </section>

            <section id="sec-the-idea">
                <div className="ideaInformations-airlock">
                    <h1 className="idea-title">THE IDEA</h1>
                    <div>
                        <p className="idea-text">The pressure on Mars is less than 1% of Earth's sea-level pressure.
                            The minimum pressure humans can survive at is about 50% of Earth's sea-level pressure, 
                            and many who are not used to such pressures can develop debilitating symptoms. Therefore, 
                            there will always be a pressure difference between the outside environment of Mars, 
                            and the human environment inside a colony. In order to exit the colony, humans will not 
                            only require a spacesuit, but also an airlock, a structure with two doors and an intermediate 
                            room between them that would connect the bring the pressure inside down to 1%, or back up to 
                            human habitation levels, depending on if you’re entering or leaving. Our team undertook the 
                            effort to not only develop our own collapsible full-size airlock system (a novel concept), 
                            but also to make a cross-Canada competition for other universities to come up with their own 
                            airlock designs.</p>
                    </div>
                </div>
            </section>

            <div className="title-seperator">
                <h1>The Competition</h1>
            </div>

            <section id="sec-details">
                <ProjectDetailsAirlock/>
            </section>

            <div className="title-seperator">
                <h1>Gallery</h1>
            </div>
            <section id="sec-gallery">
                <div className="gallery-container">
                    <ImageCard image={IMAGES.airlock_1}/>
                    <ImageCard image={IMAGES.airlock_2}/>
                    <ImageCard image={IMAGES.airlock_3}/>
                    <ImageCard image={IMAGES.airlock_4}/>
                    <ImageCard image={IMAGES.airlock_5}/>
                </div>
            </section>

            <div className="contact-us">
                <Contacts bgcolor={"red-footer"}/>
            </div>

            <div className="text-center p-3 text-white" style={{ backgroundColor: "black" }}>
                © 2022 Copyright: UBCmarscolony.com
            </div>
        </div>
    );
}

export default AirlockPage;