import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


function ProjectDetailsAirlock() {
    return (
        <>
            <style type="text/css">
                {`
    .nav-pills .nav-link.active {
      background-color: #bb3b3b;
      color: white;
    }
    
    .nav-link {
        color: black;
    }
     .nav-link:hover {
        color: #bb3b3b;
    }
    
    `}
            </style>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column projDetails">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Our Airlock</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Airlock Competition – Phase I</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Airlock Competition – Phase II</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <p>As we were running the competition, we did not enter our airlock design into consideration. Our airlock consisted of two aluminum doors, connected by a tube of ultra-high-molecular-weight polyethylene ropes and multilayered metallocene polyethylene membrane. In total, the design weighs 336 kg and its four-meter-long body can collapse to 0.5 meters.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <p>In May 2019, the first phase of the competition took place at UBC. The University of Toronto, University of Waterloo, University of Regina, and the Memorial University of Newfoundland sent representatives to present their plans for an expandable airlock.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <p>In October 2021, the second phase of the competition was held, again at UBC. Several teams arrived after having fully realised their designs of an expandable airlock. The team from Celestial Laboratories at the University of Regina won the competition, with a close second by WatLock from the University of Waterloo.</p>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        </>
    );
}

export default ProjectDetailsAirlock;