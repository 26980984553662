import styled, { createGlobalStyle } from "styled-components";

export const ThemeButton = styled.button`
  font-size: 1em;
  margin: 1.25em;
  padding: 0.25em 1em;
  border-radius: 3px;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
`;
export const GlobalStyle = createGlobalStyle`
body{
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.backgroundColor};
}
`;

export const Styled = (Component) => {
  return styled(Component)`
    color: ${(props) => props.theme.mainColor};
    .sticky {
      background-color: #212529 !important;
      transition: all 0.3s ease-out 0s !important;
      box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
      backdrop-filter: blur(15px) !important;
    }

    .navbar {
      background-color: #212529 !important;
      transition: all 0.3s ease-out 0s !important;
      padding: 0.3rem 2rem !important;
      font-size: 1.2rem !important;
    }
    
    .navbar-brand {
      color: white;
    }

    .logo {
      height: 2.5em !important;
      width: 3em !important;
    }

    .black {
      color: var(--imp-text-color) !important;
    }

    .navbar-toggler:not(.collapsed) .nav__item {
      color: #ffffff;
    }
    .logo-title {
      margin-top: 10px;
      font-size: 20px;
    }
  `;
};
