const items = [
    {
        title: "August 2019",
        cardTitle: "Sabatier Heating Tape Experiment",
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/5Y4nsQ2/sab1.jpg"
            },
            type: "IMAGE"
        },
        cardSubtitle:
            "August 2019.",
        cardDetailedText: ``
    },
    {
        title: "November 2019",
        cardTitle: "Reactor Housing Frame",
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/tPjD8ct/sab2.jpg"
            },
            type: "IMAGE"
        },
        cardSubtitle: `November 2019`,
        cardDetailedText: `This image shows the reactor housing frame being completed. This housing frame is strong enough to carry at least 100kg of weight. It is still used for the test reactor system.`
    },
    {
        title: "August 2021",
        cardTitle: "First MFC in-frame testing",
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/Jvhv2GJ/sab3.jpg"
            },
            type: "IMAGE"
        },
        cardSubtitle: `August 2021`,
        cardDetailedText: `This image shows our very first in-frame mass flow controller tests. These mass flow controllers are able to accurately control the amount of reactant gases entering the test reactor.`
    },
    {
        title: "December 2021",
        cardTitle: "Preheater Final Testing",
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/74Yp4Jg/sab4.jpg"
            },
            type: "IMAGE"
        },
        cardSubtitle: `December 2021`,
        cardDetailedText: ``
    },
    {
        title: "February 2022",
        cardTitle: "Dry run test",
        cardSubtitle: `February 2022`,
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/GdSrZHy/sab5.jpg"
            },
            type: "IMAGE"
        },
    },
    {
        title: "June 2022",
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/x10m7gQ/sab6.png"
            },
            type: "IMAGE"
        },
        cardTitle: "Sabatier Reactor Simulation Final Version",
        cardSubtitle: `June 2022`,
        cardDetailedText: `The trilogy comes to an end. With plans of using ruthenium instead of the nickel catalyst, the kinetics of the code needed to be altered. It was only then we found out that the math in the code was incorrect the whole time. The simulation was corrected and validated against multiple other research papers.`
    },
    {
        title: "June 2022",
        cardTitle: "First Drop of Water, Evidence of Methane",
        cardSubtitle: `June 2022`,
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/RNm7FXb/sab7.jpg"
            },
            type: "IMAGE"
        },
        cardDetailedText: `Although just a few drops of water this suggested that there was indeed methane being produced in the reactor. This was the moment we knew we made methane.`
    },
    {
        title: "Present day",
        cardTitle: "CH4 Production Confirmation",
        cardSubtitle: `Present`,
        media: {
            name: "Tape Experiment",
            source: {
                url: "https://i.ibb.co/BKnnS2s/sab8.png"
            },
            type: "IMAGE"
        },
        cardDetailedText: `Dr. Jing He at Clean Energy Research Center was kind enough to analyze our gas sample using gas chromatography. With actual direct readings of methane, we were able to confirm that our reactor was producing methane.`
    },
];

export default items;