import * as React from "react";
import Box from "@mui/material/Box";
import SponserShipCards from "./SponserShipCard.js";
import Marsbg from "../../images/mars-bg.jpg";
import IMAGES from "../../images/images.js";
import Spon from "../../Assets/SponsorshipGuide22-23v2.pdf";
import Contacts from "../Extras/Contacts";
import data from "bootstrap/js/src/dom/data";
import { useEffect, useState } from "react";
import CustomButton from "../Extras/CustomButton.js";
import SponsorsSlider from "../Sponsorships/SponsorsSlider";

async function fetchSponsors() {
  let data = await fetch(
    "https://script.google.com/macros/s/AKfycbyD8Z9zH5mi6r7QQYfVKrW_2mamsVsaJCho956BGWJL0I-gYhsnHp56OrJ9TGxiDnBvzw/exec"
  );
  let finalized = data.json();
  return finalized;
}

function Sponserships() {
  // let sponsors = fetchSponsors();
  const card = [
    {
      img: IMAGES.sponsor_1,
    },
    {
      img: IMAGES.sponsor_2,
    },
    {
      img: IMAGES.sponsor_3,
    },
    {
      img: IMAGES.sponsor_4,
    },
    {
      img: IMAGES.sponsor_5,
    },
    {
      img: IMAGES.sponsor_6,
    },
    {
      img: IMAGES.sponsor_7,
    },
    {
      img: IMAGES.sponsor_8,
    },
    {
      img: IMAGES.sponsor_9,
    },
    {
      img: IMAGES.sponsor_10,
    },
    {
      img: IMAGES.sponsor_11,
    },
    {
      img: IMAGES.sponsor_12,
    },
  ];

  const [sponsorsData, setSponsorsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchSponsors();
      if (Array.isArray(data.data)) {
        setSponsorsData(data.data);
      } else {
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div class="containerr">
        <img src={Marsbg} className="h-[400px] w-full mt-16 stretch" />

        <div class="centered text-6xl">Our Sponsors</div>
      </div>
      <div className="mt-6"> 
        <SponsorsSlider />  
      </div>
      <div className="justify-center" style={{ marginLeft: "18vw" }}>
        <h3
          className="mt-8 text-start font-medium w-9/12"
          style={{ color: "#a83939" }}
        >
          We've partnered with
          <span style={{ color: "#ffc400" }}> Awesome Organizations</span>
        </h3>
        <p className="text-start w-9/12">
          Thank you! We rely heavily on the support to industry and community
          partners like all those listed on this page. Their generosity has
          allowed us to achieve more that any of us thought possible.
        </p>
      </div>

      <div className="flex justify-center content-center">
        <CustomButton
          text={"Sponsorship Guide"}
          color={"red"}
          target_link={Spon}
          className="!w-[200px] !mr-4"
        />
        <CustomButton
          text={"Sponsor us now"}
          color={"red"}
          target_link={
            "https://donate.give.ubc.ca/page/80953/donate/1?transaction.dirgift=UBC+Mars+Colony%20G2316"
          }
          className="!w-[200px] !ml-4"
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "60%" }}>
          <Box
            className="mt-10 justify-center"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 3,
                width: 200,
                height: 200,
                borderRadius: 2,
              },
              justifyContent: "center",
            }}
          >
            {/* {sponsorsData.map((sponsor) => (
              <SponserShipCards imgPath={sponsor.logo} link={sponsor.link} />
            ))}
            {sponsors.then().data.map((item) => (
               <h1>{item}</h1>
               <SponserShipCards imgPath={item?.img} link={item.} />
            ))} */}
          </Box>
        </div>
      </div>

      <div className="contact-us">
        <Contacts bgcolor={"red-footer"} />
      </div>

      <div
        className="text-center p-3 text-white"
        style={{ backgroundColor: "#28282B" }}
      >
        © 2023 Copyright: UBCmarscolony.com
      </div>
    </>
  );
}
export default Sponserships;
