import React from 'react';
import "./project-card.css";

function ProjectBigCard(props) {
    return (
        <div style={{ backgroundImage: `url(${props.bgImg})` }} className="project-card-main">
            <a href={props.link} className="clickable">
                <p className="project-desc">{props.desc}</p>
                <h1 className="project-name">{props.title}</h1>
            </a>
        </div>
    );
}

export default ProjectBigCard;