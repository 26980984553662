import React from 'react';
import {Col, Row} from "react-bootstrap";
import './statistics.css';

function Statistics(props) {
    return (
        <div className="statistics-container">
            <Row className="statistics-row1">
                <Col>
                    <h1 className="statistics-number">23</h1>
                    <h1 className="statistics-number">Members</h1>
                </Col>
                <Col>
                    <h1 className="statistics-number">4</h1>
                    <h1 className="statistics-number">Sub-teams</h1>
                </Col>
            </Row>
        </div>
    );
}

export default Statistics;