import React from 'react';
import Image from 'react-bootstrap/Image';

function ImageCard(props) {
    return (
        <div>
            <Image className="image-card" src={props.image}/>
        </div>
    );
}

export default ImageCard;