import React from "react";
import Styles from "./footerStyles.css";

const Contacts = (props) => {
  return (
    <div className={`${props.bgcolor}`}>
      <footer className={"justify-around flex max-[650px]:flex-col"}>
        <div className="pb-0">
          <div className="mt-8 text-white">FOLLOW US</div>
          <section className="mt-3">
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.facebook.com/ubcmarscolony/"
              target="_blank"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://twitter.com/ubc_mars_colony?lang=en"
              target="_blank"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.instagram.com/ubcmarscolony/?hl=en"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://ca.linkedin.com/company/ubc-mars-colony"
              target="_blank"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </section>
        </div>
        <div className="vl ">
          <div className="p-4">
            <div className="text-white">EMAIL US</div>
            <section className="mt-3">
              <div className="text-white">ubcmarscolony@gmail.com</div>
            </section>
          </div>
        </div>
        <div className="vl ">
          <div className="pl-6 ">
            <div className="text-white">FIND US</div>
            <section className="mt-3 ">
              <div className="text-white">
                Lower Mall Research Station: <br />
                2259 Lower Mall, Vancouver, BC
              </div>
            </section>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contacts;
