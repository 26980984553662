import React from 'react';
import "./farming-helios-style.css";
import IMAGES from "../../../images/images";
import items from "./farmingTimelineItems";
import {Chrono} from "react-chrono";
import ProjectDetailsFarming from "./ProjectDetailsFarming";
import Contacts from "../../Extras/Contacts";
import {Badge} from "react-bootstrap";

function FarmingPage(props) {
    return (
        <div>
            <section id="sec-farming-title">
                <div className="main-title-farming">
                    <div>
                        <h4>
                            <Badge pill bg="primary">Past Project</Badge>
                        </h4>
                    </div>
                    <h1> MARS <br/> FARMING PROJECT </h1>
                    <h3><i>Bringing all your food along for a two-year mission is impractical</i></h3>
                </div>
            </section>

            <section id="sec-the-idea">
                <div className="ideaInformations-farming">
                    <h1 className="idea-title">THE IDEA</h1>
                    <div>
                        <p className="idea-text">Humans haven’t been to Mars yet, but we already know it will be a long trip.
                            The current proposed length of a crewed mission to Mars that minimizes energy required at launch 
                            (and therefore maximizes the weight of scientific and living modules) would take 34 months – 9 months 
                            travel there, 16 months waiting for the next low-energy window, and another 9 months coming back.
                            During that time, the astronauts will need to eat, and bringing all that food along would take up 
                            a lot of weight. Having the ability to grow food on-site during those 16 months on the surface of Mars 
                            would greatly decrease the weight of food they need to bring. Studies on how well food can grow in a 
                            reduced pressure environment, can show us how we can feed humans living on another planet.</p>
                    </div>
                </div>
            </section>

            <div className="title-seperator">
                <h1>Details</h1>
            </div>

            <section id="sec-details">
                <ProjectDetailsFarming/>
            </section>

            <div className="contact-us">
                <Contacts bgcolor={"red-footer"}/>
            </div>

            <div className="text-center p-3 text-white" style={{ backgroundColor: "black" }}>
                © 2022 Copyright: UBCmarscolony.com
            </div>
        </div>
    );
}

export default FarmingPage;