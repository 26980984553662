import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


function ProjectDetails() {
    return (
        <>
            <style type="text/css">
                {`
    .nav-pills .nav-link.active {
      background-color: #bb3b3b;
      color: white;
    }
    
    .nav-link {
        color: black;
    }
     .nav-link:hover {
        color: #bb3b3b;
    }
    
    `}
            </style>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column projDetails">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Reactor core</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Preheater</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Control Systems</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fourth">Mass Flow Controller</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <p>The reactor core is where the actual reaction takes place and where methane is made. It is packed with either a ruthenium or nickel-based catalyst. There is a thermocouple to measure the temperature of the gases.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <p>In the current design, the heating is provided by a coil of nichrome wire, tightly wound around the tube and sandwiched in between two layers of exhaust tape, encased in 5 inches of housing insulation on all sides. The highest temperature we ran it to was 700-degree Celsius.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <p>The control system is responsible for regulating and reporting all operation values. This includes the temperature of the system, the valves, and the mass flow controllers.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <p>The MFCs regulate the mass which passes through it. Each controller is calibrated to each of the gases which are passing through it. The maximum flow rate of the controller is 2L/min.</p>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        </>
    );
}

export default ProjectDetails;