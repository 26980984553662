// react
import React from "react";

// import Preloader from "../src/components/Pre";
import "../../App.css";
import HeroTextPart from "./HeroTextPart";
import "bootstrap/dist/css/bootstrap.min.css";
import Statistics from "../Extras/statistics";
import ParticleBg from "./ParticleBg";
import AboutInfo from "./AboutInfo";
import Contacts from "../Extras/Contacts";

import { Styled } from "../Extras/styles";
import SponsorsSlider from "../Sponsorships/SponsorsSlider";
import { Background, Parallax } from "react-parallax";
import IMAGES from "../../images/images.js";
import CustomButton from "../Extras/CustomButton";
import ProjectBigCard from "./ProjectBigCard";
import CustomButtonDown from "../Extras/CustomButtonDown";

const Home = () => {
  {
    /* <Preloader load={load} /> */
  }
  return (
    <div>
      {/*Hero-section*/}
      <Parallax
        bgImage={IMAGES.mainTrans}
        bgImageStyle={{ width: "100%", objectFit: "cover", height: "100vh" }}
        strength={150}
      >
        <div className="Main-hero-part">
          <div className="main-hero-text-part">
            <HeroTextPart />
          </div>
          <ParticleBg />
        </div>
      </Parallax>
      {/*End hero-section*/}

      {/*About us section*/}
      <div className="about-us">
        <Statistics />
        <AboutInfo />
      </div>
      <div className="sponsors-part">
        <h1 className="text-center">OUR SUPPORTERS</h1>
        <p>
          Thank you! We rely heavily on the support to industry and community
          partners like all those listed on this page. Their generosity has
          allowed us to achieve more that any of us thought possible.
        </p>
        <div className="buttons">
          <CustomButtonDown
            target_link="/sponsors"
            text={"Learn More"}
            color={"startingred"}
          />
          <CustomButton
            target="_blank"
            target_link="https://donate.give.ubc.ca/page/80953/donate/1?transaction.dirgift=UBC+Mars+Colony%20G2316"
            text={"Sponsor Us"}
            color={"red"}
          />
        </div>
      </div>

      <div className="slider-box">
        <SponsorsSlider />
        {/* <SponsorsSlider direction={"reverse"} />
        <SponsorsSlider direction={"delayed"} /> */}
      </div>
      {/*End about us section*/}

      {/*Projects section*/}
      <div className="projects">
        <div className="project-main-title">
          <h1>OUR PROJECTS</h1>
        </div>
        <div className="cards">
          <ProjectBigCard
            bgImg={IMAGES.marsChamber}
            title="Mars Chamber"
            link="Chamber"
            desc="The Martian Atmospheric Simulation Chamber (MASC) project aims to build a simulation chamber where products can be tested in a low temperature and pressure environment."
          />
          <ProjectBigCard
            bgImg={IMAGES.sabatierImg}
            title="Sabatier Fuel Plant"
            link="sabatier"
            desc="The Sabatier Fuel Plant Project aims to propose plans for a full-scale propellant manufacturing plant based on SpaceX's Mars mission architecture."
          />
        </div>
      </div>
      {/*end projects section*/}

      {/*Contacts footer*/}
      <div className="contact-us">
        <Contacts bgcolor={"red-footer"} />
      </div>

      <div
        className="text-center p-3 text-white"
        style={{ backgroundColor: "black" }}
      >
        © 2024 Copyright: UBCmarscolony.com
      </div>
      {/*end contacts section*/}
    </div>
  );
};

export default Home;
