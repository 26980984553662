import React from 'react';
import "./applyButton.css";

function ApplyButton(props) {
    return (
            <a target="_blank" href={props.target_link}>
                <button href={props.target_link} className={`custom-btn-n btn-14-n`}>{props.text}</button>
            </a>
    );
}

export default ApplyButton;