import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


function ProjectDetails() {
    return (
        <>
            <style type="text/css">
                {`.nav-pills .nav-link.active {
      background-color: #bb3b3b;
      color: white;
    }
    
    .nav-link {
        color: black;
    }
     .nav-link:hover {
        color: #bb3b3b;
    }
    
    `}
            </style>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column projDetails">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Extraction of Lunar Regolith</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Liberation of Volatile Gases</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Purifying He<sub>3</sub></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <p>The first stage of a mining operation on the Moon would be the extraction and transportation of lunar regolith to a central processing facility. The HELIOS team presented their concept for an unmanned haulage vehicle at the International Astronautical Congress (2019) in Washington DC. The next step would have been to build a small test robot, but the project was stopped after IAC.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <p>The second stage of the operation would involve heating the regolith to release its volatile components, which include He<sub>3</sub> as well as helium-4, nitrogen, methane, hydrogen, carbon dioxide, carbon monoxide, and water. This stage was planned to be presented at a future conference.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <p>The final stage of the operation is separating the He<sub>3</sub> from the other volatiles and preparing it for shipment to Earth. Additional revenue could be obtained by separating out the other volatiles. This stage was planned to be presented at a future conference.</p>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        </>
    );
}

export default ProjectDetails;