import React from "react";
import Contacts from "../../Extras/Contacts.js";
import eventImage from "../../../images/articleImages/careers-in-space-open-house.jpeg";

const Article001 = () => {
    return (
        <div className="article ">
            <div className="main-content">
                {/* ADD A BACK BUTTON */}
                <button>
                    <span className="back-button">
                        <a href="/news">
                            <p>&lt; All News</p>
                        </a>
                    </span>
                </button>

                <section>
                    <h1>UBC Mars Colony at Careers in Space</h1>
                    <hr></hr>
                    <h6>Operations Sub-Team • <em>October 28, 2024</em></h6>
                </section>
            
                <figure>
                    <img src={eventImage} className="event-image" alt=""></img>
                    <figcaption><em>Catch us at this year's Careers in Space event!</em></figcaption>
                </figure>

                <section>
                    <br></br>
                    <p>Curious about the future of space and eager to explore opportunities in this rapidly growing field? Join UBC Mars Colony and other space-focused student teams at <em>The Rise of Space Commercialization - Careers in Space</em> open house tomorrow, October 29th, in the Henry Angus Building! Hosted by CIM-MES and space teams from across UBC, this event offers an inside look at how commercial space activities are transforming industries, from resource and environmental management to remote health services and to space engineering. </p>
                    <p>Whether you're an engineering, mining, or business student—or simply have a passion for space—this open house is a fantastic opportunity to connect with experts and discover potential pathways in space commercialization. Learn from industry leaders like Raman Chawla, former ISRO Scientist, and Jeff Plate, CEO of Interstellar Mining, who'll share insights on navigating a career in this innovative sector.</p>
                    <p>Don't miss this chance to connect, ask questions, and explore how to get involved in space innovation. We hope to see you there!</p>
                    <br></br>
                </section>
                
                <p className="last-updated">----</p>
                <p className="last-updated"><em>Last Updated: October 28, 2024</em></p>
            </div>
    
            {/* Footer */}
            <section>
                <div className="contact-us">
                    <Contacts bgcolor={"red-footer"}/>
                </div>
            </section>
        </div>
    )
}

export default Article001;