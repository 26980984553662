import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade} from "swiper";
import IMAGES from "../../images/images.js";
// Import Swiper styles
import 'swiper/css';

function AboutUsSlider(props) {
    return (
        <>
            <Swiper
                modules={[Autoplay, EffectFade]}
                className="mySwiper"
                autoplay={{ delay: 3500,
                    disableOnInteraction: false }}
                loop={true}
                effect={"fade"}
                centeredSlides={true}>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.team_photo } alt="hero-img1"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_2 } alt="hero-img2"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_3 } alt="hero-img3"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_4 } alt="hero-img4"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_5 } alt="hero-img5"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_6 } alt="hero-img6"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_7 } alt="hero-img7"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="sliding-img" src={ IMAGES.hero_8 } alt="hero-img8"/>
                </SwiperSlide>

            </Swiper>
        </>
    );
}

export default AboutUsSlider;