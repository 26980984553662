import React from 'react';
import Marsbg from "../../images/mars-bg.jpg";
import SponserShipCard from "../Sponsorships/SponserShipCard";
import IMAGES from "../../images/images";
import Contacts from "../Extras/Contacts";
import ProjectsPost from "./FeaturedProject";
import FeaturedProject from "./FeaturedProject";

const Projects = () => {
  return (
    <div>
      <div className="containerr">
        <img src={Marsbg} className="h-[400px] w-full mt-16 stretch"/>

        <div className="centered text-6xl  ">Our Projects.</div>
      </div>
        <div className="Projects-center">
            <div className="card-containers">
                <FeaturedProject Title={"Mars Chamber"}
                                 Subtitle={"Test for Mars on Earth"}

                                 desc={"The Martian Atmospheric Simulation Chamber (MASC) project aims to build a simulation chamber where products can be tested in a low temperature and pressure environment."}
                                 ImageLink={IMAGES.marsChamber}
                                 link={"Chamber"}
                                 ongoing={true}
                                 reversed={false}
                />
                <FeaturedProject Title={"Sabatier Fuel Plant"}
                                 Subtitle={"The quest for fuel on Mars"}
                                 desc={"The Sabatier Fuel Plant Project aims to propose plans for a full-scale propellant manufacturing plant based on SpaceX's Mars mission architecture."}
                                 ImageLink={IMAGES.sabatierImg}
                                 link={"Sabatier"}
                                 ongoing={true}
                                 reversed={true}
                />
                <FeaturedProject Title={"Mars Farming Project"}
                                 Subtitle={"Bringing all your food along for a two-year mission is impractical"}
                                 desc={"The Mars Farming project started in October 2020 with the goal of providing a consistent food supply for crewed missions to Mars."}
                                 ImageLink={IMAGES.farmingImg}
                                 link={"Farming"}
                                 ongoing={false}
                />
                <FeaturedProject Title={"Airlock Inauguration"}
                                 Subtitle={"The Inaugural Project"}
                                 desc={"Our first highlight project was to design, prototype, and manufacture a functional and collapsible airlock."}
                                 ImageLink={IMAGES.hero_1}
                                 link={"Airlock"}
                                 ongoing={false}
                                 reversed
                />
                <FeaturedProject Title={"Helios"}
                                 Subtitle={"The quest for fuel on Mars"}
                                 desc={"The Moon is constantly bathed in a stream of Helium-3 coming from the Sun. He-3 is a clean fuel for nuclear fusion reactors, and this project set out to plan the harvest of this resource."}
                                 ImageLink={IMAGES.heliosImg}
                                 ongoing={false}
                                 link={"Helios"}/>

            </div>
        </div>
        <div className="contact-us">
            <Contacts bgcolor={"red-footer"}/>
        </div>
    </div>
  );
};

export default Projects;