import React from "react";
import Card from "react-bootstrap/Card";

function SponserShipCards(props) {
  return (
    <a className="project-card-view p-4 flex align-items-center justify-content-center" target="_blank" href={props.link}>
        <img className="center-block" src={props.imgPath} alt="card-img"/>
    </a>
  );
}
export default SponserShipCards;
