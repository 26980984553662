import React from "react";
import "./Spinner.css";
import spinner from "../../images/spinner.png";
function Spinner() {
  return (
    <div style={{ flex: 1 }}>
      <div className="loaderMain">
        <span className="loader1">
          <img src={spinner} className="img1" alt="brand" />
        </span>
      </div>
    </div>
  );
}
export default Spinner;
