import React from 'react';
import IMAGES from "../../../images/images";
import items from "./heliosTimelineItems";
import {Chrono} from "react-chrono";
import ProjectDetails from "../ProjectDetails";
import ProjectDetailsHelios from "./ProjectDetailsHelios";
import {Badge} from "react-bootstrap";

function HeliosPage(props) {
    return (
        <div>
            <section style={{background: IMAGES.heliosImg}} id="sec-helios-title">
                <div className="main-title">
                    <div>
                        <h4>
                            <Badge pill bg="primary">Past Project</Badge>
                        </h4>
                    </div>
                    <h1>Helios</h1>
                    <h3><i>Can we extract sustainable energy from the moon?</i></h3>
                </div>
            </section>

            <section id="sec-the-idea">
                <div className="ideaInformations">
                    <h1 className="idea-title">THE IDEA</h1>
                    <p className="idea-text">If we want to move away from using oil & gas as an energy source, 
                        we need some other source to move to. Helium-3 (He<sub>3</sub>) is one such possible energy source.
                        Future nuclear fusion reactors may be able to take advantage of He<sub>3</sub>, producing energy
                        without producing radioactive material. However, there is very little He<sub>3</sub> available on
                        Earth, and what little there is would require more energy to extract than its fusion 
                        would produce. The moon’s regolith, on the other hand, contains a much higher percentage 
                        of He<sub>3</sub> than found anywhere on Earth. A mining operation on the Moon might provide humanity
                        with the energy source we need to move into the future, but a thorough feasibility study 
                        must be done on Earth to determine if such an effort is worth it.</p>
                </div>
            </section>

            <div className="title-seperator"><h1>Mining Operation Details</h1></div>

            <section id="sec-details">
                <ProjectDetailsHelios/>
            </section>

        </div>
    );
}

export default HeliosPage;