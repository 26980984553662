import React from 'react';
import {Col, Row} from "react-bootstrap";
import Marsbg from "../../images/mars-bg.jpg";

function ArticlePreview(props) { 
    return (
        <div className="article-container transition hover:-translate-y-1 ease-in-out duration-300">
            <a href={props.link}>
                <Row className="w-full h-full">
                    {/* Text */}
                    <Col className="article-text content-center">
                        <h2 className="hover:text-[#bb3b3b] transition ease-in-out">{props.title}</h2>
                        <p>{props.date}</p>
                    </Col>
                    {/* Image & gradient overlay */}
                    <Col xs="4" className="gradient-image">
                        <div className="gradient"></div>
                        <img src={props.imageLink ? props.imageLink : Marsbg}/>
                    </Col>
                </Row>
            </a>
        </div>
    )
}

export default ArticlePreview;