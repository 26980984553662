import React from "react";
import Contacts from "../Extras/Contacts.js";
import Marsbg from "../../images/mars-bg.jpg";
import "./News.css";
import ArticlePreview from "./ArticlePreview";

import eventImage1 from "../../images/articleImages/careers-in-space-open-house.jpeg";


const News = () => {
    return (
        <div className="bg-[#f0f0f0]">
            {/* Header */}
            <section className="containerr">
                <img src={Marsbg} alt="" className="h-[400px] w-full mt-16 stretch"/>

                <div className="centered text-6xl">Recent News</div>
            </section>

            {/* Main Content */}
            <section className="article-section">
                <div>
                    <ArticlePreview link={"UBC-Mars-Colony-at-Careers-in-Space"}
                                    date={"October 28, 2024"}
                                    title={"UBC Mars Colony at Careers in Space"}
                                    imageLink={eventImage1}
                    />
                </div>
            </section>

            {/* Footer */}
            <section>
                <div className="contact-us">
                    <Contacts bgcolor={"red-footer"}/>
                </div>
            </section>
        </div>
    );
}

export default News;